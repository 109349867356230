import React from "react";
import AllServices from "../components/Services/AllServices";

export const ServicesList = () => {
  return (
    <div>
      <AllServices />
    </div>
  );
};
